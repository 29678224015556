import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [
        {
            title: "Strelīcija",
            image: "https://i.imgur.com/boPxvfQb.jpg",
            native: "Dienvidāfrika",
            description: "Paradīzes putna zieds, cilvēki to mēdz saukt par papagaiļa puķi. Interesanti, ka tā ir tik ļoti cienīta, ka tas ir attēlots uz 50 centu monētas reversa puses. Vāzē tās var stāvēt līdz pat 2 nedēļām.",
            // care: "Ik pēc 2 dienām, kad maini ūdeni vāzē, nogriez tām kātiņus aptuveni 2 cm no apakšas, 45 grādu leņķī, lai zieds varētu uzsūkt vajadzīgo ūdeni un tajā esošās barības vielas. Rozes ilgst līdz vienai nedēļai.",
        },
        {
            title: "Strelīciju lapas",
            image: "https://i.imgur.com/lluF7Blb.jpg",
            native: "Strelīciju lapas",
            description: "Skaistas, lielas topiska paskata lapas, kas brīvā dabā var izaugt līdz pat 50cm augstumā. Dēļ auga krāšņuma un ziediem, tie kļuvuši populāri, kā istabas augi.",
            // care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām. Īpaši sargi no saules un siltuma avotiem. Neturi blakus augļiem. ",
        },
        
        {
            title: "Arālijas lapas",
            image: "https://i.imgur.com/X1qvw0Ob.jpg",
            native: "Āzija/Amerikas",
            description: "Arālijas augam ir aptuveni 70 paveidi. Tas tiek uzskatīts par žeņšeņa radinieku un senākos laikos no tā taisīja gan tējas, gan izmantoja to medicīniskiem nolūkiem, kā balzāmu, lai sadziedētu apdegumus un brūces (mēs protams neisakām to izmēģināt mājās).",
            // care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām.",
        },
        {
            title: "Amarillis Royal Velvet",
            image: "https://i.imgur.com/nntvX2eb.jpg",
            native: "Āzija",
            description: "Ziediem ir karaliski samtaini sarkana krāsa. Amariļļi ir unikāli ar to ziedu krāsu un tekstūru, kas nav sastopamas nevienos citos ziedos. Vāzē amarillis pakāpeniski izziedēs. Parasti amariļļa dzīves ilgums vāzē ir 7-14 dienas.",
            // care: "Ik pēc 2 dienām, kad maini ūdeni vāzē, nogriez tām kātiņus aptuveni 2 cm no apakšas, 45 grādu leņķī, lai zieds varētu uzsūkt vajadzīgo ūdeni un tajā esošās barības vielas. Rozes ilgst līdz vienai nedēļai.",
        },
        {
            title: "Samoa rozes",
            image: "https://i.imgur.com/HNMT0ilb.jpg",
            native: "Āzija",
            description: "Unikālas rozes. Tajās var redzēt zaļu salu, oranžā rožu ziedlapu jūrā. Ne velti nosauktas Samoa Atolla vārdā. Vai zināji, ka pasaulē ir vairāk par 25 000 rožu šķirņu?",
            care: "Ik pēc 2 dienām, kad maini ūdeni vāzē, nogriez tām kātiņus aptuveni 2 cm no apakšas, 45 grādu leņķī, lai zieds varētu uzsūkt vajadzīgo ūdeni un tajā esošās barības vielas. Rozes ilgst līdz vienai nedēļai.",
        },
        {
            title: "Hiperikums",
            image: "https://i.imgur.com/exEcjs8b.jpg",
            native: "Dienvidāfrika",
            description: "Joprojām neesam pārliecināti, ka šis ir pareizais tulkojums Latviešu valodā, bet noteikti zinām, ka tās nāk no Hypericaceae, dzimtas, kas ir radniecīga asinszālei. Tām ir skaistas, krāsainas ogas un ilgs mūžs vāzē, kā dēļ to arī bieži izmanto ziedu kompozīcijās.",
            // care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām. Neturi blakus augļiem.",
        }
    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">

                    <h2 class="title is-3" style={{ color: '#eee' }}>Instrukcija</h2>

                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/f54f4597-d15e-45cd-8341-6e05b5060a20/playlist.m3u8"
                        captions="/titles/004.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Drosmīgiem cilvēkiem, drosmīga kompozīcija</h2>

                            <p>Šoreiz mēs izvēlējāmies patiesi eksotisku ziedu salikumu, jo visticamāk, mājās ir skaistas tulpes vai citas pavasara puķes. </p>
                                
                            <p>Mēs ne tikai iekļāvām spēcīgas krāsas, bet arī kliedzošus ziedus, lai nobaidītu tikko atsākušos ziemu. Pati kompozīcija ir mazliet izaicinoša gan stila, gan sakārtošanas ziņā. Tāpēc ilgi domājām vai šo Tev sūtīt, bet beigās noriskējām. </p>

                            <p>Mēs ceram, ka pieņemsi šo kompozīciju kā izaicinājumu, jo dēļ ziedu izmēra un atšķirīgās formas, tā var būt grūtāk sakārtojama.</p>

                            <p>Kompozīcijā ziedi un augi ir sadalīti zonās, sarkanā, oranžīgā un zaļā. Vislabāk derēs pēc iespējas garāka vāze ar vidēji platu kaklu. Viedojot kompozīciju, vari domāt par trim līmeņiem: </p>

                            <ul>
                                <li>Apakšējais - zaļās lapas</li>
                                <li>Vidējais - sarkanie amarillis un rozes</li>
                                <li>Augšējais - paradīzes putni zaros :)</li>
                            </ul>

                            <p>Ķeramies pie darba!</p>

                            <ol>
                                <li>Vāzē lej ūdeni istabas temperatūrā </li>
                                <li>Pievieno ziedu barību, 1 paciņa uz 0,5l ūdens, samaisi</li>
                                <li>Obligāti, visiem ziedu stublājiem nogriez 2cm no apakšas, lai tie spētu uzsūkt ūdeni un ilgāk paliktu svaigi</li>
                                <li>Strelīciju ziedus var uzmanīgi atvērt, skaties <a href="https://youtu.be/IieWcF8F1lY">video šeit</a></li>
                                <li>Strelīciju un Arāliju lapas tiek kārtotas pa perimetru, ap vāzes malām un dekoratīvu zaru</li>
                                <li>Tālāk vāzes priekšpusē vienā zonā izkārto Samoa rozes</li>
                                <li>Tad Hiperikuma ogas</li>
                                <li>Vāzes priekšpusē izkārto sarkanos Amariļļus, kopā - vienā zonā</li>
                                <li>Visbeidzot dažādos līmeņos izkārto Strelīcijas, iesakam kompozīcijas aizmugurē. Tās var būt 3 pakāpēs, garākas par priekšpuses ziediem</li>
                                <li>Droši izmanto zaru, kas ļaus garos ziedus atbalstīt un novietot īstajā pozīcijā</li>
                                {/* <li>Pirmais triks, ziedu stublājiem jābūt vienā garumā, lai spētu izveidot apaļu kompozīciju, to garums ir atkarīgs no Tavas vāzes augstuma</li> */}
                                {/* <li>Sāc ar zaļi baltajiem zariņiem (Pittosporum), kur tos pavisam vienkārši izkārto vāzē - šie zariņi ir kā skelets kompozīcijai, kas dos apjomu un noturēs formu </li> */}
                                {/* <li>Tālāk liec stap zariem Viburnum Roseum jeb sniega bumbas. Triks ir izmantot zaļi baltos zariņus, kā atbalstus, lai uz tiem uzliktu smagos ziedus, kā arī tie ļauj simetriski izkārtot ziedu bumbas kompozīcijā. Grūtākais ir padarīts</li> */}
                                {/* <li>Izkārto vāzē pildītās, lielās Rozes, Krūmrozes un smaržīgās Gerberas </li> */}
                                {/* <li>Visbeidzot kā pēdējās liec vāzē Anemones, tie ir ļoti trausli ziedi, tāpēc tās tiks piegādās vēl pumpuros, bet atrodoties istabas temperatūrā tās pavisam neilgā laikā atvērsies</li> */}
                            </ol>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Messenger čatā, loga stūrī!</p>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja  koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/ozaikZ0h.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Īpaši labi ziedi stāvēs, ja ūdeni tiem mainīsi reizi 2 dienās. Kā to pareizi darīt? </p>

                            <ol>
                                <li>Noskalo ziedu kātus</li>
                                <li>Izmazgā vāzi</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
